const apiUrl = process.env.REACT_APP_ANTHROPOD_API_URL;
const apiKey = process.env.REACT_APP_DEMO_DETAILS_API_KEY;

class Server {
    async demoDetails(data) {
      try {
        let url = apiUrl + "/api/internal/website_demo_details/";
        const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${apiKey}`
            },
            body: JSON.stringify(data)
          });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        let status = response.status;

        const resData = await response.json();
        return {resData, status};
        
      } catch (error) {
        console.error('Error while calling demoDetails API:', error);
        throw error;
      }
    }
  }

const server = new Server();

export default server;