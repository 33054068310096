import React, { useEffect, useState } from "react"; // Updated import statement
import FormComponent from "./FormComponent";

const IntegrateIntoCRM = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenForm = (val) => {
    setOpenForm(val);
  };

  let [isMobile, setIsMobile] = useState(window.innerWidth < 760); // Updated isMobile initialization

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };

    // Add event listener on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={`flex my-8 ${openForm ? "blur-sm" : ""}`}>
        <div className="flex-1"></div>
        <div
          className="flex-3 rounded-[38px] border border-black flex items-center"
          style={{ flexDirection: isMobile ? "column" : "", margin: "15px" }}
        >
          <div className="p-5 flex-1">
            <div
              style={{
                display: "flex",
                width: isMobile ? "100%" : "100%",
                height: isMobile ? "100%" : "",
                justifyContent: "center",
              }}
            >
              <div
                className="font-bold text-center"
                style={{
                  maxWidth: isMobile ? "245px" : "",
                  fontSize: isMobile ? "25px" : "30px",
                }}
              >
                Integrate Anthropod into your CRM
              </div>
            </div>
            <div
              className="font-normal max-w-[372px] text-center"
              style={{
                fontSize: isMobile ? "11px" : "14px",
                marginLeft: isMobile ? "" : "",
                marginTop: isMobile ? "-10px" : "",
              }}
            >
              Free your team from tedious CRM updates - let them focus on what
              really matters: preparing for impactful calls
            </div>
          </div>
          <div
            className="bg-black rounded-[22px] flex-1 h-full flex justify-evenly text-center items-center flex-col"
            style={{
              padding: "40px",
              width: "100%",
              marginTop: isMobile ? "32px" : "",
            }}
          >
            <div
              className="text-[22px] font-extrabold text-white max-w-[335px]"
              style={{ marginBottom: "2rem" }}
            >
              Double your agent's productivity
            </div>
            <div
              onClick={() => setOpenForm(true)}
              className="bg-white rounded-[36px] w-[180px] text-[#277AE8] text-19px"
              style={{ padding: "0.3rem", fontSize: "700" }}
            >
              Book a Demo
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
      {openForm && (
        <div
          style={{
            position: "fixed",
            zIndex: "999999",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            background: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: "999999999",
              top: "15%",
              left: isMobile ? "6%" : "33%",
            }}
          >
            <FormComponent setOpenForm={handleOpenForm} />
          </div>
        </div>
      )}
    </>
  );
};

export default IntegrateIntoCRM;
