import React, { useEffect, useState } from "react"; // Combined import statements
import solutionsData from "../utils/SolutionsData";
import FormComponent from "./FormComponent";

const SolutionsComponent = () => {
  let [currTab, setCurrTab] = useState(0);
  let [tabData, setTabData] = useState(solutionsData.items[0]);
  const [openForm, setOpenForm] = useState(false);
  let [isMobile, setIsMobile] = useState(window.innerWidth < 760); // Updated isMobile initialization

  const handleChangeTab = (i) => {
    setCurrTab(i);
    setTabData(solutionsData.items[i]);
  };
  const handleOpenForm = (val) => {
    setOpenForm(val);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };

    // Add event listener on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: isMobile ? "" : "", // Converted 35em to px
        }}
        className={`${openForm ? "blur-sm" : ""}`}
      >
        <div
          style={{
            flex: !isMobile ? "0.15" : "",
            display: isMobile ? "none" : "",
          }}
        ></div>
        <div
          style={{
            background: "rgba(246,246,246,1)",
            padding: "1rem",
            flex: !isMobile ? "0.7" : "",
            width: isMobile ? "100%" : "",
          }}
        >
          <div
            style={{
              fontSize: isMobile ? "16px" : "21px",
              fontWeight: "550",
              textAlign: "center",
            }}
          >
            An Assistant That Does Everything From <br />
            Acquisition to Retention
          </div>
          <div
            style={{
              textAlign: "center",
              margin: "2rem 0rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                overflowX: "scroll",
                width: isMobile ? "528px" : "", // Converted 33em to px
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 0.2rem",
                }}
              >
                <div
                  onClick={() => handleChangeTab(0)}
                  style={{
                    background: currTab === 0 ? "#2357E8" : "white",
                    color: currTab === 0 ? "white" : "black",
                    border: currTab === 0 ? "" : "1px solid #c7c7c7",
                    padding: "10px",
                    borderRadius: "6px",
                    fontSize: isMobile ? "13px" : "14.5px",
                    fontWeight: "500",
                    cursor: "pointer",
                    width: isMobile ? "150px" : "",
                  }}
                >
                  Sales Solutions
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 0.2rem",
                }}
              >
                <div
                  onClick={() => handleChangeTab(1)}
                  style={{
                    background: currTab === 1 ? "#2357E8" : "white",
                    color: currTab === 1 ? "white" : "black",
                    border: currTab === 1 ? "" : "1px solid #c7c7c7",
                    padding: "10px",
                    borderRadius: "6px",
                    fontSize: isMobile ? "13px" : "14.5px",
                    fontWeight: "500",
                    cursor: "pointer",
                    width: isMobile ? "140px" : "",
                  }}
                >
                  Marketing Solutions
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 0.2rem",
                }}
              >
                <div
                  onClick={() => handleChangeTab(2)}
                  style={{
                    background: currTab === 2 ? "#2357E8" : "white",
                    color: currTab === 2 ? "white" : "black",
                    border: currTab === 2 ? "" : "1px solid #c7c7c7",
                    padding: "10px",
                    borderRadius: "6px",
                    fontSize: isMobile ? "13px" : "14.5px",
                    fontWeight: "500",
                    cursor: "pointer",
                    width: isMobile ? "190px" : "",
                  }}
                >
                  Customer Support Solutions
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 0.2rem",
                }}
              >
                <div
                  onClick={() => handleChangeTab(3)}
                  style={{
                    background: currTab === 3 ? "#2357E8" : "white",
                    color: currTab === 3 ? "white" : "black",
                    border: currTab === 3 ? "" : "1px solid #c7c7c7",
                    padding: "10px",
                    borderRadius: "6px",
                    fontSize: isMobile ? "13px" : "14.5px",
                    fontWeight: "500",
                    cursor: "pointer",
                    width: isMobile ? "140px" : "",
                  }}
                >
                  Product Solutions
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: !isMobile ? "flex" : "", alignItems: "center" }}
          >
            <div style={{ display: isMobile ? "none" : "", flex: "0.2" }}></div>
            <div style={{ flex: !isMobile ? "0.6" : "" }}>
              <div
                style={{
                  fontSize: isMobile ? "14px" : "18px",
                  fontWeight: "550",
                  padding: "0 0px 0 10px",
                }}
              >
                {tabData.title}
              </div>
              <div style={{ height: "27vh" }}>
                <ul style={{ margin: "15px 5px" }}>
                  {tabData.briefs.map((item, idx) => (
                    <li
                      key={idx}
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        padding: "3px",
                        color: "grey",
                        listStyleType: "none",
                      }}
                    >
                      <span>✔️ </span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div style={{ display: isMobile ? "none" : "", flex: "0.1" }}></div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{
                  width: "100%",
                  height: !isMobile ? "40vh" : "",
                  maxHeight: "860px",
                  maxWidth: "860px",
                }}
                src={tabData.imgUrl}
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          style={{
            flex: !isMobile ? "0.15" : "",
            display: isMobile ? "none" : "",
          }}
        ></div>
      </div>
      {openForm && (
        <div
          style={{
            position: "fixed",
            zIndex: "999999",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            background: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: "999999999",
              top: "15%",
              left: window.innerWidth < 760 ? "6%" : "33%",
            }}
          >
            <FormComponent setOpenForm={handleOpenForm} />
          </div>
        </div>
      )}
    </>
  );
};

export default SolutionsComponent;
