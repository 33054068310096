import React, { useEffect, useState } from "react";

const WidgetResultComponent = ({ currWidget }) => {
  const [title, setTitle] = useState({
    icon: "/assets/softSkills.svg",
    value: "Agent Skills",
    desc: "Find the right leads and companies.",
    backgroundColor: "rgba(221, 236, 255, 1)",
    bgColor: "rgba(221, 236, 255, 1)",
    backgroundImage: "/assets/aisolBackgroundImgs/agentSkills.webp",
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 760); // Added isMobile state

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (currWidget) {
      console.log("something", currWidget);
      setTitle(currWidget);
    }
  }, [currWidget]);

  return (
    <div
      style={{
        background: "white",
        borderRadius: "0px 25px 25px 0px",
        padding: "15px",
        flex: isMobile ? "" : "0.6", // Use isMobile for conditional rendering
        marginTop: isMobile ? "" : "2rem", // Use isMobile for conditional rendering
        marginLeft: isMobile ? "" : "",
        width: isMobile ? "105%" : "",
      }}
    >
      <div>
        <div
          style={{
            padding: "15px",
            backgroundColor: title.bgColor,
            borderRadius: "9px 9px 0px 0px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={title.icon} alt="" />
            <div style={{ marginLeft: "10px" }}>{title.value}</div>
          </div>
        </div>
        <div>
          <img
            src={title.backgroundImage}
            style={{
              width: "100%",
              height: isMobile ? "auto" : "", // Use isMobile for conditional rendering
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default WidgetResultComponent;
