import React, { useEffect, useState } from "react";
import WidgetChangeComponent from "./WidgetChangeComponent.js";

const AiSolutionWidget = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [isLaptop, setIsLaptop] = useState(
    window.innerWidth >= 768 && window.innerWidth < 1440
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
      setIsLaptop(window.innerWidth >= 768 && window.innerWidth < 1440);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div style={{ marginTop: "30px", width: isMobile ? "90%" : "" }}>
      <div
        style={{
          textAlign: "center",
          fontWeight: "500",
          fontSize: window.innerWidth < 760 ? "16px" : "25px",
          marginLeft: isMobile ? "40px" : "5em",
        }}
      >
        <h2>Listen, Analyse, Act with Anthropod</h2>
      </div>
      <div
        style={{
          textAlign: "center",
          fontWeight: "500",
          fontSize: window.innerWidth < 760 ? "14px" : "18px",
          paddingLeft: isMobile ? "48px" : "",
        }}
      >
        Turn Customer Calls into Key Business Insights
      </div>
      <div style={{ display: "flex" }}>
        <WidgetChangeComponent />
      </div>
    </div>
  );
};

export default AiSolutionWidget;
