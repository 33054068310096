import React, { useEffect, useState } from "react";
import FormComponent from "../FormComponent";
import "../styles/Navbar.css";

const Navbar = () => {
  const [openForm, setOpenForm] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showFullNav, setShowFullNav] = useState(true);

  // Handle form open
  const handleOpenForm = () => {
    setOpenForm(true);
    setMenuOpen(false);
  };

  // Toggle burger menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleOpenFormer = (val) => {
    setOpenForm(val);
  };
  const scrollToSection = (id) => {
    const section = document.querySelector(id);
    const navbarHeight = 80; // Adjust this value to match your navbar's height
    const sectionPosition = section.offsetTop - navbarHeight;

    window.scrollTo({
      top: sectionPosition,
      behavior: "smooth",
    });
  };

  // Listen for scroll events
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50 && window.innerWidth < 760) {
        setShowFullNav(false);
      } else {
        setShowFullNav(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="all">
      {window.innerWidth > 760 ? (
        <>
          <>
            <div
              className={`flex my-[30px] fixed top-0 w-full z-[1000] ${
                openForm ? "blur-sm" : ""
              }`}
              style={{ marginTop: "18px" }}
            >
              <div className="flex-[0.35]"></div>
              <div
                className="flex items-center flex-[0.6] w-full p-[10px] rounded-[15px] bg-[rgba(233,233,233,0.36)] backdrop-blur-[8px]"
                style={{ maxWidth: "120em" }}
              >
                <div className="flex-[0.3]">
                  <img
                    src="/assets/anthro_logo_1.svg"
                    style={{ height: "3rem" }}
                    alt=""
                  />
                </div>
                {/* <div className="flex-[0.2]"></div> */}
                <div
                  className="flex flex-[0.7] justify-between items-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <a
                    href="#home"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection("#home");
                    }}
                  >
                    <div className="text-[16px] font-medium cursor-pointer">
                      Home
                    </div>
                  </a>
                  <a
                    href="#features"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection("#features");
                    }}
                  >
                    <div className="text-[16px] font-medium cursor-pointer">
                      Features
                    </div>
                  </a>
                  <a
                    href="#solutions"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection("#solutions");
                    }}
                  >
                    <div className="text-[16px] font-medium cursor-pointer">
                      Solutions
                    </div>
                  </a>
                  <a
                    href="#faq"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection("#faq");
                    }}
                  >
                    <div className="text-[16px] font-medium cursor-pointer">
                      FAQ
                    </div>
                  </a>
                  <div
                    onClick={handleOpenForm}
                    className="font-semibold cursor-pointer text-white bg-black px-[10px] py-[0px] h-[35px] w-[120px] rounded-[7px] flex items-center justify-center text-[16px]"
                    style={{ fontWeight: "500" }}
                  >
                    Book a Demo
                  </div>
                </div>
              </div>
              <div className="flex-[0.35]"></div>
            </div>
          </>
        </>
      ) : (
        <>
          <div
            className={`flex fixed top-0 w-full z-[1000] ${
              openForm ? "blur-sm" : ""
            }`}
            style={{ marginTop: "15px", marginBottom: "30px" }}
          >
            <div
              className={`${showFullNav ? "flex-[0.05]" : "flex-[0.9]"}`}
            ></div>

            {/* Navbar (Logo + Book a Demo + Burger Icon) */}
            <div
              className={`flex items-center w-full p-[10px] rounded-[15px] bg-[rgba(233,233,233,0.36)] "backdrop-blur-[8px] ${
                showFullNav ? "flex-[0.9]" : "flex-[0.05]"
              }`}
              style={{ justifyContent: "end" }}
            >
              {showFullNav && (
                <>
                  <div className="flex-[0.5]">
                    <img src="/assets/anthro_logo_1.svg" alt="Anthropod Logo" />
                  </div>
                  <div
                    className="flex-[0.4] text-end cursor-pointer"
                    onClick={handleOpenForm}
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Book a Demo
                  </div>
                </>
              )}

              {/* Mobile Menu (Burger Icon) */}
              <div className="flex md:hidden flex-[0.2] justify-end items-center">
                <div
                  onClick={toggleMenu}
                  className={`cursor-pointer text-black text-[30px] font-bold`}
                >
                  ☰ {/* Burger icon */}
                </div>
              </div>
            </div>
            <div className="flex-[0.05]"></div>
          </div>

          {/* Sidebar Menu */}
          <div
            style={{ zIndex: "9999" }}
            className={`fixed top-0 left-0 h-full w-[250px] bg-[rgba(233,233,233,0.9)] p-5 z-[999] transform transition-transform duration-300 ${
              menuOpen ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <div className="flex flex-col items-start gap-4">
              {/* Close Button */}
              <div
                onClick={toggleMenu}
                className="cursor-pointer text-black text-[25px] font-bold self-end"
              >
                ✖ {/* Close icon */}
              </div>
              <a href="#home" onClick={() => setMenuOpen(false)}>
                <div className="text-[18px] font-bold cursor-pointer">Home</div>
              </a>
              <a href="#features" onClick={() => setMenuOpen(false)}>
                <div className="text-[18px] font-medium cursor-pointer">
                  Features
                </div>
              </a>
              <a href="#solutions" onClick={() => setMenuOpen(false)}>
                <div className="text-[18px] font-medium cursor-pointer">
                  Solutions
                </div>
              </a>
              <a href="#faq" onClick={() => setMenuOpen(false)}>
                <div className="text-[18px] font-medium cursor-pointer">
                  FAQ
                </div>
              </a>
              <div
                onClick={handleOpenForm}
                className="font-semibold cursor-pointer text-white bg-black px-[10px] py-[5px] h-[51px] w-[150px] rounded-[7px] flex items-center justify-center text-[19px]"
              >
                Book a Demo
              </div>
            </div>
          </div>
        </>
      )}

      {openForm && (
        <div
          style={{
            position: "fixed",
            zIndex: 1000,
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            background: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 1001,
              top: "15%",
              left: window.innerWidth < 760 ? "6%" : "33%",
            }}
          >
            <FormComponent setOpenForm={handleOpenFormer} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
