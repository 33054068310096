const solutionsData = {
  items: [
    {
      type: "Sales Solutions",
      bgColor:
        "radial-gradient(127.71% 76.6% at 66.54% 76.97%, #D99A79 0%, #C87D55 100%)",
      title: "Maximise revenue from your existing funnels",
      briefs: [
        "Real Time Monitoring of Sales Funnel",
        "Customer Sentiment Analysis",
        "Agent Assist for better query handling",
        "Enriching Customer Attributes",
        "Agent feedback for Quality Audit & Training",
        "Set Automated Follow-Ups",
      ],
      imgUrl: "/assets/salessolutionfinalbg.webp",
    },
    {
      type: "Marketing Solutions",
      title: "Maximize ROI through Enhanced Marketing Insights",
      bgColor:
        "radial-gradient(127.71% 76.6% at 66.54% 76.97%, #D9D979 0%, #C8C455 100%)",
      briefs: [
        "Enhance Cross-Selling Opportunities",
        "Conversion Rate Optimization",
        "Campaign Performance Tracking",
        "Lead Scoring and Nurturing",
        "Automated Personalized Communications",
        "Get Ai-Generated Content Summaries for FAQs, Blogs, Social Media Posts, Webinars, etc",
      ],
      imgUrl: "/assets/marketingfinalbg.webp",
    },
    {
      type: "Customer Support Solutions",
      title: "Improve customer support with actionable insights",
      bgColor:
        "radial-gradient(127.71% 76.6% at 66.54% 76.97%, #7ED979 0%, #5FC855 100%)",
      briefs: [
        "Support Ticket Prioritization",
        "Identify unhappy customers",
        "Categorise escalations",
        "AI resolutions to reduce churn",
        "Customer Feedback Analysis",
        "Knowledge Base Management",
      ],
      imgUrl: "/assets/customersupportfinalbg.webp",
    },
    {
      type: "Product Solutions",
      title: "Leveraging AI Insights to Enhance Product Positioning",
      bgColor:
        "radial-gradient(127.71% 76.6% at 66.54% 76.97%, #7989D9 0%, #556CC8 100%)",
      briefs: [
        "Real-time automated CRM updates",
        "Integration of customer feedback",
        "User experience (UX) insights",
        "Tracking product performance metrics",
        "Insights for product adoption",
      ],
      imgUrl: "/assets/productfinalbg.webp",
    },
  ],
};

export default solutionsData;
