import React, { useEffect, useState } from "react";
import FormComponent from "./FormComponent";

const DashboardHeader = () => {
  const [openForm, setOpenForm] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 760 && window.innerWidth <= 1050
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
      setIsTablet(window.innerWidth >= 760 && window.innerWidth <= 1050);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOpenForm = (val) => {
    setOpenForm(val);
  };

  return (
    <>
      <div
        className={`${openForm ? "blur-sm" : ""} ${
          isMobile || isTablet ? "block" : "flex"
        } items-center mb-[30px]`}
        style={{
          maxWidth: "100%", // Use full width of the screen
          margin: "0", // Remove margin to align to the left edge
          padding: isMobile ? "0 15px" : "0", // Adjust padding for mobile
          marginLeft: isMobile ? "-42px" : "",
          overflowX: isMobile || isTablet ? "hidden" : "",
        }}
      >
        {/* Header Text */}
        <div
          className="my-[15px]"
          style={{
            textAlign: isMobile ? "center" : "left",
            marginTop: !isMobile ? "45px" : "15px",
            flex: 1,
            marginRight: !isMobile ? "-90px" : "",
          }}
        >
          <span
            style={{
              marginRight: isMobile ? "" : "20px",
              paddingRight: isMobile ? "" : "120px",
            }}
          >
            <div
              className="font-bold"
              style={{
                fontSize: isMobile ? "14px" : "",
                lineHeight: isMobile ? "50px" : "62px",
              }}
            >
              <h1
                style={{
                  background: "rgb(22,21,21)",
                  background:
                    "linear-gradient(180deg, rgba(22,21,21,1) 15%, rgba(26,40,83,1) 44%, rgba(26,40,83,1) 47%, rgba(28,51,117,1) 59%, rgba(31,67,169,1) 82%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                }}
              >
                AI-Powered Call<br></br> Analytics for <br></br>Customer
                <br></br>
                Interactions
              </h1>
            </div>

            <div
              className="font-bold m-6"
              style={{
                fontWeight: "400",
                margin: !isMobile ? "24px 0" : "24px",
                fontSize: isMobile ? "20px" : "15px",
              }}
            >
              Anthropod is an AI tool for call and text analytics that
              <br /> delivers customer insights, provides real-time agent
              <br />
              support, and conducts call audits for quality training.
            </div>

            {/* Book a Demo Button */}
            <div
              className="mt-[15px] flex flex-col justify-center items-start" // Align items to start
              style={{
                paddingTop: !isMobile ? "" : "",
                margin: isMobile ? "0 15px" : "-8px 0 0 0 ",
              }}
            >
              <div
                className="w-full text-white font-semibold cursor-pointer"
                style={{
                  margin: "10px 0",
                  borderRadius: "6px",
                  backgroundColor: "#2357E8",
                  width: isMobile ? "100%" : "50%",
                  boxShadow: !isMobile
                    ? "rgba(0, 0, 0, 0.2) 0px 4px 100px 0px, rgba(0, 0, 0, 0.19) 0px 3px 8px 0px"
                    : "",
                  height: "43px",
                }}
                onClick={() => setOpenForm(true)}
              >
                <div className="flex items-center justify-center h-full">
                  Book a Demo
                </div>
              </div>
              <div
                style={{
                  fontSize: isMobile ? "13px" : "15px",
                  marginLeft: isMobile ? "44px" : "",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  lineHeight: "1px",
                }}
              >
                <p>Get a free audit of your contact center</p>
              </div>
            </div>
          </span>
        </div>

        {/* YouTube Video */}
        <div
          style={{
            height: isMobile ? "38vh" : "",
            textAlign: "center",
            margin: "0.5rem 0 0", // Further reduced top margin to decrease gap
            marginLeft: !isMobile ? "-100px" : "",
            padding: "1rem",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: isMobile ? "88vw" : "",
              marginTop: isMobile ? "" : "-10px",
            }}
          >
            <iframe
              width="560"
              height="315"
              style={{
                height: isMobile ? "30vh" : "50vh",
                borderRadius: !isMobile ? "15px" : "",
                maxHeight: "30em",
                marginLeft: isMobile ? "-35px" : "401px",
              }}
              src="https://www.youtube-nocookie.com/embed/BxYNwYO48dI?si=nXHmvmvmAgWCPav4&rel=0&modestbranding=1&controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      </div>

      {openForm && (
        <div
          style={{
            position: "fixed",
            zIndex: 1000,
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            background: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 1001,
              top: "15%",
              left: isMobile ? "6%" : "33%",
            }}
          >
            <FormComponent setOpenForm={handleOpenForm} />
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardHeader;
