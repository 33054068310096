import React, { useEffect, useState } from "react"; // Combined import statements
import FormComponent from "./FormComponent";

const GetStartedComponent = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenForm = (val) => {
    setOpenForm(val);
  };

  let [isMobile, setIsMobile] = useState(window.innerWidth < 760); // Updated isMobile initialization

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };

    // Add event listener on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        style={{
          margin: isMobile ? "32px 0px" : "64px 0",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: isMobile ? "12px" : "25px",
            fontWeight: "500",
            marginLeft: isMobile ? "20px" : "",
            width: isMobile ? "90%" : "",
          }}
        >
          <h2>How to get started on Anthropod</h2>
        </div>
        <div
          style={{
            display: "flex",
            margin: "32px 0px",
            justifyContent: "center",
            marginLeft: isMobile ? "40px" : "",
            width: isMobile ? "432px" : "",
            height: isMobile ? "128px" : "",
          }}
        >
          <div
            style={{
              display: isMobile ? "none" : "",
              flex: !isMobile ? "0.15" : "",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: !isMobile ? "space-around" : "space-evenly",
              width: isMobile ? "85%" : "100%",
              marginLeft: isMobile ? "-150px" : "",
              flex: !isMobile ? "0.7" : "",
            }}
          >
            <div
              style={{
                height: isMobile ? "70px" : "80px",
                width: isMobile ? "80px" : "150px",
                padding: "16px",
                textAlign: "center",
                borderRadius: "6px",
                background: "rgb(35, 87, 232)",
                color: "white",
                fontSize: isMobile ? "13px" : "18px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={handleOpenForm}
            >
              Book a Demo
            </div>
            <div
              style={{
                color: "rgb(153 153 153)",
                fontSize: isMobile ? "15px" : "30px",
              }}
            >
              {" "}
              &#10095;{" "}
            </div>
            <div
              style={{
                height: isMobile ? "70px" : "80px",
                width: isMobile ? "80px" : "150px",
                padding: "12px",
                textAlign: "center",
                borderRadius: "6px",
                background: "rgba(243, 243, 243, 1)",
                fontSize: isMobile ? "13px" : "18px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              1 Month Free POC
            </div>
            <div
              style={{
                color: "rgb(153 153 153)",
                fontSize: isMobile ? "15px" : "30px",
              }}
            >
              {" "}
              &#10095;{" "}
            </div>
            <div
              style={{
                height: isMobile ? "70px" : "80px",
                width: isMobile ? "90px" : "150px",
                padding: "12px",
                textAlign: "center",
                borderRadius: "6px",
                background: "rgba(243, 243, 243, 1)",
                fontSize: isMobile ? "13px" : "18px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Scale the Use Cases
            </div>
          </div>
          <div
            style={{
              display: isMobile ? "none" : "",
              flex: !isMobile ? "0.15" : "",
            }}
          ></div>
        </div>
      </div>
      {openForm && (
        <div
          style={{
            position: "fixed",
            zIndex: 1000,
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            background: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 1001,
              top: "15%",
              left: window.innerWidth < 760 ? "6%" : "33%",
            }}
          >
            <FormComponent setOpenForm={handleOpenForm} />
          </div>
        </div>
      )}
    </>
  );
};

export default GetStartedComponent;
