import React, { useEffect, useState } from "react";

const AnthropodMagic = () => {
  let [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 760) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  return (
    <div
      style={{
        margin: isMobile ? "16px" : "48px 288px", // converted rem to px (1rem = 16px)
        boxShadow: isMobile
          ? "rgba(0, 0, 0, 0.2) 0px 4px 100px 0px, rgba(0, 0, 0, 0.19) 0px 3px 8px 0px"
          : "rgba(0, 0, 0, 0.2) 0px 4px 5px 0px, rgba(0, 0, 0, 0.19) 0px 3px 6px 0px",
        padding: "32px 8px", // converted rem to px (2rem = 32px, 0.5rem = 8px)
        borderRadius: isMobile ? "38px" : "15px",
        width: isMobile ? "92%" : "",
        height: isMobile ? "288px" : "", // converted em to px (18em = 288px)
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: isMobile ? "16px" : "21px",
          fontWeight: "550",
        }}
      >
        Impacts on your business metrics
      </div>
      <div
        style={{
          margin: isMobile ? "16px 0" : "32px", // converted rem to px (1rem = 16px, 2rem = 32px)
          display: !isMobile ? "flex" : "",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flex: !isMobile ? "0.5" : "",
          }}
        >
          <div style={{ textAlign: "center", flex: "0.5" }}>
            <div
              style={{
                color: "rgb(98 139 255)",
                fontSize: "40px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              30%{" "}
              <span style={{ fontSize: "25px", color: "rgb(98 139 255)" }}>
                ↑
              </span>
            </div>
            <div
              style={{ color: "#898686", fontWeight: "500", fontSize: "15px" }}
            >
              Agent Performance
            </div>
          </div>
          <div style={{ textAlign: "center", flex: "0.5" }}>
            <div
              style={{
                color: "rgb(98 139 255)",
                fontSize: "40px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              37%
              <span style={{ fontSize: "25px", color: "rgb(98 139 255)" }}>
                ↑
              </span>
            </div>
            <div
              style={{ color: "#898686", fontWeight: "500", fontSize: "15px" }}
            >
              Talk Time
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flex: !isMobile ? "0.5" : "",
          }}
        >
          <div style={{ textAlign: "center", flex: "0.5" }}>
            <div
              style={{
                color: "rgb(98 139 255)",
                fontSize: "40px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              20%
              <span style={{ fontSize: "25px", color: "rgb(98 139 255)" }}>
                ↑
              </span>
            </div>
            <div
              style={{ color: "#898686", fontWeight: "500", fontSize: "15px" }}
            >
              LTV
            </div>
          </div>
          <div style={{ textAlign: "center", flex: "0.5" }}>
            <div
              style={{
                color: "#ff6d6d",
                fontSize: "40px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              35%<span style={{ fontSize: "25px", color: "#ff6d6d" }}>↓</span>
            </div>
            <div
              style={{ color: "#898686", fontWeight: "500", fontSize: "15px" }}
            >
              Churn
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnthropodMagic;
