const WidgetChangeData = {
    "titleHeadings": ["Build Pipeline", "Close Deals", "Improve Performance", "Streamline Operations"],
    "titleObjects": [
        {
            "titleHeading": "AUDIT WITH AI",
            "color": "rgba(86, 159, 255, 1)", 
            "bgColor": "rgba(221, 236, 255, 1)",
            "titleItems": [
                {
                    value: "Agent Skills", icon: "/assets/softSkills.svg", desc: "Find the right leads and companies.", backgroundColor: "rgba(86, 159, 255, 1)", "bgColor": "rgba(221, 236, 255, 1)", backgroundImage: "/assets/aisolBackgroundImgs/agentSkills.webp"
                }, 
                {
                    value: "Adherence & Violations", icon: "/assets/adherence.svg", desc: "Reach out to the right leads.", backgroundColor: "rgba(86, 159, 255, 1)", "bgColor": "rgba(221, 236, 255, 1)", backgroundImage: "/assets/aisolBackgroundImgs/adherenceViolations.webp"
                }, 
                {
                    value: "Agent Assist", icon: "/assets/agentTraining.svg", desc: "Automate your outbound.", backgroundColor: "rgba(86, 159, 255, 1)", "bgColor": "rgba(221, 236, 255, 1)", backgroundImage: "/assets/aisolBackgroundImgs/agentAssist.webp"
                }
            ]
        }, 
        {
            "titleHeading": "SALES PIPELINE",
            "color": "rgba(34, 195, 223, 1)" ,
            "bgColor": "rgba(229, 251, 255, 1)",
            "titleItems": [
                {
                    value: "Customer Intent", icon: "/assets/customerIntent.svg", desc: "See what's working and where to improve.", backgroundColor: "rgba(34, 195, 223, 1)", "bgColor": "rgba(229, 251, 255, 1)", backgroundImage: "/assets/aisolBackgroundImgs/customerIntent.webp"
                }, 
                // {
                //     value: "Lead Scoring", icon: "/assets/spotNewLeads.svg", desc:"Give your reps actionable feedback.", backgroundColor: "rgba(34, 195, 223, 1)", "bgColor": "rgba(229, 251, 255, 1)", backgroundImage: "/assets/aisolBackgroundImgs/leadScoringbg.png"
                // },
                {
                    value: "Identifying Sales Opportunities", icon: "/assets/identifyingSalesOpportunities.svg", desc:"Give your reps actionable feedback.", backgroundColor: "rgba(34, 195, 223, 1)", "bgColor": "rgba(229, 251, 255, 1)", backgroundImage: "/assets/aisolBackgroundImgs/identifyingSalesOpportunities.webp"
                }
            ]
        }, 
        {
            "titleHeading": "CUSTOMER SUPPORT",
            "color": "rgba(51, 222, 122, 1)",
            "bgColor": "rgba(234, 255, 243, 1)",
            "titleItems": [
                {
                    value: "Identify Unhappy Customers", icon: "/assets/identifyUnhappyCustomers.svg", desc: "Prepare for, book and follow up on meetings.", backgroundColor: "rgba(51, 222, 122, 1)", "bgColor": "rgba(234, 255, 243, 1)", backgroundImage: "/assets/aisolBackgroundImgs/identifyUnhappyCustomers.webp"
                }, 
                {
                    value: "Reduce Churn", icon: "/assets/reduceChurn.svg", desc: "Close deals with insight and recommendations.", backgroundColor: "rgba(51, 222, 122, 1)", "bgColor": "rgba(234, 255, 243, 1)", backgroundImage: "/assets/aisolBackgroundImgs/reduceChurn.webp"
                }
            ]
        }, 
        {
            "titleHeading": "WORKFLOW AUTOMATION",
            "color": "rgba(233, 75, 198, 1)", 
            "bgColor": "rgba(255, 232, 250, 1)",
            "titleItems": [
                {
                    value: "Automated CRM Updates", icon: "/assets/automatedCRMUpdates.svg", desc: "Automate tasks and move faster on leads.", backgroundColor: "rgba(233, 75, 198, 1)", "bgColor": "rgba(255, 232, 250, 1)", backgroundImage: "/assets/aisolBackgroundImgs/automatedCRMUpdates1.webp"
                }, 
                {
                    value: "Customised Communications", icon: "/assets/customerProfile.svg", desc: "Automatically sync and enrich your CRM.", backgroundColor: "rgba(233, 75, 198, 1)", "bgColor": "rgba(255, 232, 250, 1)", backgroundImage: "/assets/aisolBackgroundImgs/customizedCommunications.webp"
                }, 
                {
                    value: "Call Summary", icon: "/assets/summaries.svg", desc: "Automate tasks and move faster on leads.", backgroundColor: "rgba(233, 75, 198, 1)", "bgColor": "rgba(255, 232, 250, 1)", backgroundImage: "/assets/aisolBackgroundImgs/callSummary.webp"
                }
            ]
        },
        {
            "titleHeading": "MARKETING & PRODUCT",
            "color": "rgba(210, 142, 106, 1)" ,
            "bgColor": "rgba(255, 242, 235, 1)",
            "titleItems": [
                {
                    value: "Identify top-performing channels", icon: "/assets/OptimiseCampaigns.svg", desc: "See what's working and where to improve.", backgroundColor: "rgba(210, 142, 106, 1)", "bgColor": "rgba(255, 242, 235, 1)", backgroundImage: "/assets/aisolBackgroundImgs/identifyTopPerformingChannels.webp"
                }, 
                {
                    value: "Customer-centric content", icon: "/assets/customerCentricContent.svg", desc:"Give your reps actionable feedback.", backgroundColor: "rgba(210, 142, 106, 1)", "bgColor": "rgba(255, 242, 235, 1)", backgroundImage: "/assets/aisolBackgroundImgs/customerCentricContent.webp"
                },
                // {
                //     value: "Gain actionable product insights", icon: "/assets/optimiseCampaigns.svg", desc:"Give your reps actionable feedback.", backgroundColor: "rgba(210, 142, 106, 1)", "bgColor": "rgba(255, 242, 235, 1)", backgroundImage: "/assets/aisolBackgroundImgs/agentSkillsbg.png"
                // }
            ]
        }
    ]
};

export default WidgetChangeData;