import React, { useEffect, useState } from "react";

const WidgetTitleItemsComponent = ({ titleobjects, prop_selected_widget }) => {
  const [selectedIdx, setSelectedIdx] = useState("Agent Skills");
  const [showHoverIndex, setShowHoverIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760); // Added isMobile state

  const handleChangeTitle = (val) => {
    setSelectedIdx(val.value);
    prop_selected_widget(val);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        flex: isMobile ? "0.15" : "0.1",
        background: "white",
        borderRadius: "25px 0px 0px 25px",
        padding: "20px 5px",
      }}
    >
      <div
        style={{
          marginTop: "1rem",
          display: isMobile ? "flex" : "",
          overflowX: "scroll",
          width: isMobile ? "100%" : "",
        }}
      >
        {titleobjects.map((titleObj, idx) => {
          return (
            <div key={idx}>
              <div
                style={{
                  padding: "10px",
                  color: titleObj.color,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                {titleObj.titleHeading}
              </div>
              <div style={{ paddingLeft: "10px" }}>
                {titleObj.titleItems.map((titleItem, index) => {
                  return (
                    <div
                      key={index}
                      onMouseEnter={() => setShowHoverIndex(titleItem.value)}
                      onMouseLeave={() => setShowHoverIndex(null)}
                      onClick={() => handleChangeTitle(titleItem)}
                      style={{
                        display: "flex",
                        padding: "8px",
                        margin: "3px",
                        cursor: "pointer",
                        height: "30px",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "max-content",
                        background:
                          selectedIdx === titleItem.value ||
                          showHoverIndex === titleItem.value
                            ? titleItem.bgColor
                            : "",
                        borderRadius:
                          selectedIdx === titleItem.value ||
                          showHoverIndex === titleItem.value
                            ? "12px"
                            : "",
                        border:
                          selectedIdx === titleItem.value
                            ? "1px solid " + titleItem.backgroundColor
                            : "",
                      }}
                    >
                      <img
                        src={titleItem.icon}
                        alt=""
                        style={{ height: "17px" }}
                      />
                      <div
                        style={{
                          marginLeft: "10px",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        {titleItem.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: "100%",
          display: isMobile ? "flex" : "none",
          justifyContent: "end",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: "600",
          marginTop: "10px",
        }}
      >
        {" "}
        Scroll ⇨
      </div>
    </div>
  );
};

export default WidgetTitleItemsComponent;
