import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./Main"; // Import your other components as needed
import Privacy from "./components/global/Privacy"; // Adjust the path as necessary
import Terms from "./components/global/Terms"; // Adjust the path as necessary

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  );
};

export default AppRoutes;
