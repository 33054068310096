import React, { useEffect, useState } from "react";
import { AiOutlineYoutube } from "react-icons/ai";
import { IoIosMail, IoMdContact } from "react-icons/io";
import { LiaCopyrightSolid } from "react-icons/lia";
import { PiLinkedinLogo } from "react-icons/pi"; // Correctly importing the LinkedIn icon
import { Link } from "react-router-dom";

const SocialIcon = ({ src, alt }) => (
  <img
    loading="lazy"
    src={src}
    className="shrink-0 w-6 aspect-square"
    alt={alt}
  />
);

const Footer = () => {
  let [isMobile, setIsMobile] = useState(false);
  let [isTab, setIsTab] = useState(false); // useState hook for screen width <= 1670px

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760); // Mobile check
      setIsTab(window.innerWidth <= 1670); // Tablet or smaller screen width <= 1670px
    };

    handleResize(); // Set the initial state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        background: "black",
        padding: "24px", // Converted 1.5rem to 24px
        display: !isMobile ? "flex" : "",
        justifyContent: "center",
        alignItems: "center",
        width: isMobile ? "100%" : "",
      }}
    >
      <div
        style={{ display: !isMobile ? "block" : "none", flex: "0.15" }}
      ></div>
      <div style={{ flex: !isMobile ? "0.7" : "" }}>
        <div
          style={{
            margin: "32px 0", // Converted 2rem to 32px
            display: !isMobile ? "flex" : "",
            justifyContent: !isMobile ? "space-between" : "",
          }}
        >
          <div style={{ flex: "0.33" }}>
            <div
              style={{ color: "white", fontSize: "25px", fontWeight: "700" }}
            >
              Contact Details
            </div>
            <div
              style={{
                color: "white",
                fontSize: "14px",
                fontWeight: "400",
                maxWidth: isMobile ? "270px" : "",
                marginTop: "10px",
              }}
            >
              219, 3rd Cross Road, 1st Block, Koramangala, Bengaluru,
              {isMobile && <br style={{ height: "16px" }} />}
              Karnataka - 560034
            </div>
            <div
              style={{
                color: "white",
                fontSize: "14px",
                marginTop: "10px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => window.open("mailto:info@anthropod.in")}
            >
              <IoIosMail style={{ fontSize: "20px", marginRight: "10px" }} />
              Info@anthropod.in
            </div>
            <div
              style={{
                color: "white",
                fontSize: "14px",
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IoMdContact style={{ fontSize: "20px", marginRight: "10px" }} />
              +91 70233 29599
            </div>
          </div>
          <div style={{ flex: "0.33" }}>
            <div style={{ margin: isMobile ? "32px 0 16px" : "" }}>
              <div
                style={{
                  color: "white",
                  fontSize: "25px",
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Quick Links{" "}
              </div>
            </div>
            <div style={{ margin: "16px 0" }}>
              <div
                style={{
                  color: "grey",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "8px",
                }}
              >
                About us
              </div>
              <div
                style={{
                  color: "grey",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "8px",
                }}
              >
                Careers
              </div>
              <div
                style={{
                  color: "grey",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "8px",
                }}
              >
                Resources
              </div>
              <div
                style={{
                  color: "grey",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "8px",
                }}
              >
                Blogs
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: isMobile ? "column" : "column",
                  gap: isMobile ? "8px" : "8px",
                  marginLeft: isMobile ? "-10px" : "-10px",
                  marginRight: "-90px",
                  marginTop: isMobile ? "8px" : "8px",
                }}
              >
                <Link
                  to="/privacy"
                  style={{ color: "white", margin: "0 10px" }}
                >
                  Privacy Policy
                </Link>
                <Link to="/terms" style={{ color: "white", margin: "0 10px" }}>
                  Terms and Conditions
                </Link>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ color: "white", marginTop: isMobile ? "" : "-20px" }} />
        <br></br>
        <br style={{ display: !isMobile ? "none" : "" }}></br>
        <div
          style={{
            display: "flex",
            margin: "1px 0", // Converted 1rem to 16px
            justifyContent: "space-evenly",
            gap: isMobile ? "90px" : "740px",
          }}
        >
          <div
            style={{
              marginTop: isMobile ? "-30px" : "",
              marginLeft: isMobile ? "-16px" : "",
            }}
          >
            <div
              style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "white",
                marginTop: isMobile ? "1px" : "8px",
                marginLeft: isMobile ? "-10px" : "",
                display: "flex",
                whiteSpace: isTab ? "nowrap" : "normal", // Ensure the footer remains on one line in tab view
              }}
            >
              <LiaCopyrightSolid
                style={{
                  fontSize: "20px",
                  marginRight: isMobile ? "8px" : "15px",
                }}
              />
              2024 Anthropod
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: isMobile ? "20%" : "40%",
              alignItems: "center",
              marginLeft: !isMobile ? "-200px" : "",
              marginTop: isMobile ? "-40px" : "",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "20px",
                marginLeft: isMobile ? "8px" : "400px",
                marginBottom: isMobile ? "-10px" : "",
              }}
            >
              <div
                onClick={() =>
                  window.open("https://www.youtube.com/@anthropod_ai", "_blank")
                }
                style={{
                  display: isMobile ? "block" : "",
                }}
              >
                <AiOutlineYoutube
                  style={{ fontSize: "30px", color: "white" }}
                />
              </div>
              <div
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/anthropod-ai/",
                    "_blank"
                  )
                }
                style={{ display: isMobile ? "block" : "" }}
              >
                <PiLinkedinLogo style={{ fontSize: "30px", color: "white" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: !isMobile ? "block" : "none", flex: "0.15" }}
      ></div>
    </div>
  );
};

export default Footer;
