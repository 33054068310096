import React, { useEffect, useState } from "react";

const SeamlessIntegration = () => {
  let [isMobile, setIsMobile] = useState(window.innerWidth < 760); // Updated initialization

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };

    // Add event listener on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        margin: "1rem 0",
        display: !isMobile ? "flex" : "",
        background: !isMobile ? "white" : "",
        padding: !isMobile ? "3rem 0" : "",
        width: isMobile ? "100%" : "",
      }}
    >
      <div
        style={{
          flex: !isMobile ? "0.2" : "",
          display: isMobile ? "none" : "",
        }}
      ></div>
      <div
        style={{
          flex: isMobile ? "0.35" : "0.5",
          display: isMobile ? "" : "flex",
          flexDirection: isMobile ? "" : "column",
          justifyContent: isMobile ? "" : "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: isMobile ? "85%" : "",
              fontSize: isMobile ? "10px" : "25px",
              fontWeight: isMobile ? "500" : "500",
              textAlign: "center",
            }}
          >
            <h2>Anthropod Integration with other apps</h2>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: isMobile ? "1rem 2rem" : "1rem",
          }}
        >
          <div
            style={{
              color: "black",
              fontSize: isMobile ? "13px" : "17px",
              fontWeight: "400",
              textAlign: "center",
              width: isMobile ? "98%" : "",
            }}
          >
            Anthropod AI seamlessly integrates with your IVR, <br /> CRM,
            communication channels, meeting <br /> platforms, chatbots and more.
          </div>
        </div>
      </div>
      <div
        style={{
          flex: !isMobile ? "0.1" : "",
          display: isMobile ? "none" : "",
        }}
      ></div>
      <div>
        <img
          src="/assets/seamlessInteg.png"
          style={{ height: isMobile ? "" : "45vh", maxHeight: "50em" }}
          alt=""
        />
      </div>
      <div
        style={{
          flex: !isMobile ? "0.2" : "",
          display: isMobile ? "none" : "",
        }}
      ></div>
    </div>
  );
};

export default SeamlessIntegration;
